<template>
    <div class="m-3">
        Pregled naročenega za kupca
    </div>
</template>

<script>
    export default {
        name: "Ordered",
        components: {},
        props: {},
        data() {
            return {}
        },
        mounted() {
        },
        methods: {},
        computed: {},
        filters: {},
        watch: {}
    }
</script>

<style scoped>

</style>
